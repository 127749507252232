
import checkForErrors from '@/helpers/checkForErrors';
import conversationAPI from '@/api/conversations';
import hasCustomFields from '@/mixins/hasCustomFields';
import { mapGetters, mapState } from 'vuex';
import NewAccountSelector from '../NewAccountSelector.vue';
import loginsAPI from '@/api/logins';

export default {
  name: 'new-conversation',
  components: { NewAccountSelector },
  mixins: [hasCustomFields],

  props: {
    depth: {
      type: String,
      required: true,
    },
    depth_uuid: {
      type: String | null,
      required: true,
    },
    participants: {
      type: Array | null,
      required: false,
    },
  },

  data() {
    return {
      modelType: 'conversation',
      newConvo: {
        name: '',
        participants: [],
        contents: '',
      },
      accountParticipants: [],
      newClientsWithoutLogins: [],
      client: {},
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['currentAccountUuid', 'isEmployee']),

    accountSelectorType() {
      if (this.depth === 'employee') {
        return 'employee';
      }
      if (this.depth === 'external') {
        return 'external';
      } else {
        return 'client';
      }
    },
  },

  created() {
    this.newConvo = this.buildDataBlob(this.newConvo);
  },

  mounted() {
    // if we're already at client / case level then we want to merge incoming participants with the current depth
    if (this.isEmployee) {
      let people = [];
      if (this.participants && this.participants.length) {
        people = this.participants;
      }

      this.accountParticipants = people;
    } else {
      this.accountParticipants = null;
    }
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    async createConversation() {
      if (this.depth === 'case' && this.depth_uuid) {
        this.newConvo = { ...this.newConvo, case: this.depth_uuid };
      }

      if (this.newConvo.participants.length === 0) {
        delete this.newConvo.participants;
      }
      try {
        const rsp = await conversationAPI.createConversation(this.newConvo);
        this.$nuxt.$emit('refreshConversations');
        this.newConvo = {
          name: '',
          participants: this.participants,
          contents: '',
        };
        this.$nuxt.$emit('viewConvo', rsp.data.data);
      } catch (err) {
        if (err.response.status === 403) {
          this.$nuxt.$emit('notification', err.response.data.message, 'warning', true);
        }
        checkForErrors.process(err, this.$refs.formErrorPanel);
      }
    },
    async getClientsWithoutLogins(participants, isNewParticipant = false) {
      if (!Array.isArray(participants) || participants.length === 0) {
        return [];
      }

      try {
        const clientParticipants = participants.filter((p) => p.type === 'client');
        const clientLoginsWithParticipants = await Promise.all(
          clientParticipants.map(async (participant) => ({
            participant,
            loginData: await loginsAPI.getAll(participant.uuid),
          }))
        );

        return clientLoginsWithParticipants
          .filter(({ loginData }) => loginData.data.data.length === 0)
          .map(({ participant }) => (isNewParticipant ? participant : participant.uuid));
      } catch (error) {
        this.$nuxt.$emit('notification', 'Unable to check for clients without logins', 'warning');
        return [];
      }
    },
  },

  watch: {
    accountParticipants: {
      handler: async function (new_val) {
        if (new_val !== null) {
          this.newConvo.participants = new_val.map((v) => v.uuid);
          this.newClientsWithoutLogins = await this.getClientsWithoutLogins(new_val, true);
        } else {
          this.newClientsWithoutLogins = [];
        }
      },
    },
  },
};
